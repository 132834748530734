import React from 'react';
import logo from './fork.png';
import { version } from '../package.json';
import { RedocStandalone } from 'redoc';
import spec from './spec.json';
import './App.css';

const theme = {
  colors: {
    primary: {
      main: '#1ab1ac'
    }
  },
  menu: {
    backgroundColor: '#1ab1ac',
    textColor: '#fff',
    width: '150px'
  },
  spacing: {
    unit: 5,
    sectionVertical: 10
  },
  rightPanel: {
    width: '60%'
  }
}

function App() {
  return (
    <div className="main">
      <header>
        <span style={{ zIndex: 1000 }}>version: {version}</span>
      </header>
      <div className="content">
        <RedocStandalone
          spec={spec}
          options={{
            disableSearch: true,
            jsonSampleExpandLevel: 6,
            expandResponses: "200,201",
            theme: theme
          }}
        />
      </div>

    </div>
  );
}

export default App;
